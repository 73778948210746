<template>
  <div>
    <el-row type="flex" class="row-bg hBox" justify="space-around">
      <el-col :xs="24" :sm="18" :md="16" :lg="14" :xl="12">
        <div>
          <Artplayer ref="artplayer" :style="style" :muted="true"/>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-around" style="margin-top: 20px">
      <el-col :xs="24" :sm="18" :md="16" :lg="14" :xl="12">
        <el-input placeholder="播放地址" v-model="url">
          <template slot="prepend">URL</template>
          <el-button slot="append" icon="el-icon-video-play" @click="changePlay"></el-button>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Artplayer from "../../../components/Artplayer.vue";

export default {
  name: "m3u8Play",
  data() {
    return {
      url: '',
      style: {
        margin: "10px auto 0",
        "aspect-ratio": "16/9"
      },
    };
  },
  components: {
    Artplayer,
  },
  mounted() {
    let url =  this.$route.query.encipher === "0" ?  unescape(this.$route.query.url) : this.$route.query.url;
    let title = this.$route.query.title;
    if (url != null) {
      this.url = url
      this.$refs.artplayer.changeUrl(url);
    }
    if (title) {
      document.title = this.$t('title_') + title
    }
  },
  methods: {
    changePlay() {
      this.$refs.artplayer.changeUrl(this.url)
    },
  },
};
</script>